<template lang="html">
  <div class="ui modal report">
    <i class="ui close icon"></i>
    <div class="content" v-if="deliverable">
      <deliverable-card
        ref="card"
        v-if="deliverable.inferred_type != 'instagram_stories'"
        :initial-deliverable="deliverable"
        :can-show-more="false"
        :key="deliverable.id"
        class="fluid"
      ></deliverable-card>

      <instagram-story-embed
        v-else
        class="storyEmbed"
        :deliverable="deliverable"
      ></instagram-story-embed>
    </div>

    <i
      v-if="deliverables.length > 1"
      class="icon large chevron left link"
      @click="previous"
    ></i>
    <i
      v-if="deliverables.length > 1"
      class="icon large chevron right link"
      @click="next"
    ></i>
  </div>
</template>

<script>
import deliverableCard from "./deliverableCard.vue";
import InstagramStoryEmbed from "views/deliverables/components/instagramStoryEmbed.vue";

const $ = window.jQuery;

export default {
  props: {
    deliverables: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      deliverable: null,
    };
  },
  components: {
    deliverableCard,
    InstagramStoryEmbed,
  },
  updated() {
    const $this = this;
    $this.$nextTick(() => {
      $this.refresh();
    });
  },
  computed: {
    currentIndex() {
      return this.deliverables.findIndex((e) => e.id === this.deliverable.id);
    },
    nextIndex() {
      let nextIndex = this.currentIndex + 1;
      if (nextIndex > this.deliverables.length - 1) {
        nextIndex = 0;
      }
      return nextIndex;
    },
    prevIndex() {
      let prevIndex = this.currentIndex - 1;
      if (prevIndex < 0) {
        prevIndex = this.deliverables.length - 1;
      }
      return prevIndex;
    },
  },
  methods: {
    next() {
      this.setDeliverable(this.deliverables[this.nextIndex]);
    },
    previous() {
      this.setDeliverable(this.deliverables[this.prevIndex]);
    },
    setDeliverable(deliverable) {
      this.deliverable = deliverable;
      this.$nextTick(() => {
        if (window.iframely) {
          window.iframely.load();
        }
        this.open();
        this.refresh();
      });
    },
    open() {
      $(this.$el).modal("show");
    },
    close() {
      $(this.$el).modal("hide");
    },
    refresh() {
      $(this.$el).modal("refresh");
    },
    initModal() {
      const $this = this;
      $($this.$el).modal({
        detachable: true,
        observeChanges: true,
        onShow: () => {
          // $this.refresh();
        },
      });

      $this.$nextTick($this.refresh);
    },
  },
};
</script>

<style lang="scss" scoped>
.storyEmbed {
  width: 300px;
  margin: 0px auto;
}

.content {
  position: relative;
}

i.right,
i.left {
  position: absolute;
  top: 45%;
}

i.left {
  left: 10px;
}

i.right {
  right: 10px;
}
</style>
