<template lang="html">
  <div class="">
    <deliverable-card-modal ref="modal" :deliverables="deliverables" />

    <!-- <template v-for="group in groupedDeliverablesByUser"> -->
    <template v-for="group in sortedGroups">
      <user-header
        v-if="userGroupsSize > 1"
        :key="group.user_identifier"
        :user="group.user"
        :username="group.username"
        :deliverables="group.other"
        :stories="group.instagram_stories"
        :user-groups-size="userGroupsSize"
        class="ui segment"
        @toggle="toggleSection"
      />

      <div
        v-if="group.profiles.length > 0"
        class="ui three column grid card-grid stretched"
      >
        <div
          v-for="deliverable in group.profiles"
          class="column"
          style="max-width: 50%"
        >
          <a v-if="pdfReport" :href="deliverable.url">
            <img v-if="deliverable.card" :src="deliverable.card.expiring_url" />
          </a>

          <profile-card
            v-if="!pdfReport"
            :key="deliverable.id"
            :initial-deliverable="deliverable"
            class="fluid"
          />
        </div>
      </div>

      <div
        v-show="!collapsedGroups.includes(group.user.id)"
        :key="group.user.id + '_cards'"
        class="ui three doubling stackable cards card-grid deliverable-cards-center"
      >
        <template
          v-for="stories in groupDeliverablesByUsername(
            group.instagram_stories
          )"
        >
          <template v-if="expandStories && pdfReport">
            <div
              v-for="story in stories"
              class="ui deliverable card"
              :class="[`${story.inferred_type}-card`]"
            >
              <a v-if="story.card" :href="story.url">
                <img v-if="story.card" :src="story.card.expiring_url" />
              </a>
              <deliverable-card
                v-else
                :key="story.id"
                :initial-deliverable="story"
              />
            </div>
          </template>
          <template v-else>
            <instagram-stories-card
              :instagram-stories="stories"
              :lazy-load="lazyLoad"
              @openModal="openModal"
            />
          </template>
        </template>

        <template v-for="deliverable in group.other">
          <div
            v-if="pdfReport && deliverable.card"
            class="ui deliverable card"
            :class="[`${deliverable.inferred_type}-card`]"
          >
            <a :href="deliverable.url">
              <img
                v-if="deliverable.card"
                :src="deliverable.card.expiring_url"
              />
            </a>
          </div>

          <deliverable-card
            v-else
            :key="deliverable.id"
            :initial-deliverable="deliverable"
            :lazy-load="lazyLoad"
            @openModal="openModal"
            @cardLoaded="cardLoaded"
          />
        </template>
      </div>
    </template>

    <div v-if="allDeliverablesLoaded" id="allDeliverablesLoaded" />
  </div>
</template>

<script>
import { size, every, groupBy, find, xor } from "lodash";
import deliverableCard from "./deliverableCard.vue";
import profileCard from "./profileCard.vue";
import deliverableCardModal from "./deliverableCardModal.vue";
import instagramStoriesCard from "./instagramStoriesCard.vue";
import userHeader from "./_userHeader.vue";

export default {
  components: {
    deliverableCard,
    profileCard,
    deliverableCardModal,
    instagramStoriesCard,
    userHeader,
  },
  props: {
    deliverables: {
      type: Array,
      default() {
        return [];
      },
    },
    expandStories: {
      type: Boolean,
      default: false,
    },
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    pdfReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsedGroups: [],
    };
  },
  computed: {
    userGroupsSize() {
      return size(this.groupedDeliverablesByUser);
    },
    sortedGroups() {
      return Object.values(this.groupedDeliverablesByUser).sort((a, b) => {
        return b.deliverables_count - a.deliverables_count;
      });
    },
    groupedDeliverablesByUser() {
      const deliverablesByUser = {};
      this.deliverables.forEach((deliverable) => {
        const user_identifier = `${deliverable.user_id}-${deliverable.user_login}`;

        deliverablesByUser[user_identifier] = deliverablesByUser[
          user_identifier
        ] || {
          instagram_stories: [],
          profiles: [],
          other: [],
          user_identifier: user_identifier,
          username: deliverable.user_login,
          user: deliverable.user,
          deliverables_count: 0,
        };
        // deliverablesByUser[deliverable.user_id].deliverables_count += 1;
        deliverablesByUser[user_identifier].deliverables_count += 1;

        if (deliverable.inferred_type === "instagram_stories") {
          // deliverablesByUser[deliverable.user_id].instagram_stories.push(
          deliverablesByUser[user_identifier].instagram_stories.push(
            deliverable
          );
        } else if (deliverable.inferred_type.includes("_profile")) {
          // deliverablesByUser[deliverable.user_id].profiles.push(deliverable);
          deliverablesByUser[user_identifier].profiles.push(deliverable);
        } else {
          // deliverablesByUser[deliverable.user_id].other.push(deliverable);
          deliverablesByUser[user_identifier].other.push(deliverable);
        }
      });
      return deliverablesByUser;
    },
    allDeliverablesLoaded() {
      // return every(this.groupedDeliverables.other, d => d.hasBeenLoaded);
      return every(this.deliverables, (d) => d.hasBeenLoaded);
    },
  },
  mounted() {
    if (typeof window.iframely !== "undefined") {
      window.iframely.load();
    }

    if (window.location.search.includes("collapseUserHeaders")) {
      this.collapseUserHeaders();
    }
  },
  methods: {
    collapseUserHeaders() {
      this.collapsedGroups = Object.keys(
        this.groupedDeliverablesByUser
      ).map((k) => parseInt(k));
    },
    toggleSection(id) {
      this.collapsedGroups = xor(this.collapsedGroups, [id]);
    },
    groupDeliverablesByUsername(deliverables) {
      return groupBy(deliverables, "provider_username");
    },
    openModal(deliverable) {
      this.$refs.modal.setDeliverable(deliverable);
    },
    cardLoaded(deliverable) {
      const del = find(this.deliverables, ["id", deliverable.id]);
      if (del) {
        this.$set(del, "hasBeenLoaded", true);
      }
    },
  },
};
</script>

<style lang="css">
.statistics-head {
  flex-grow: 0 !important;
}

@media (max-width: 767.98px) {
  .deliverable-cards-center {
    margin-left: -1.35rem !important;
    margin-right: 0rem !important;
  }

  .ui.three.doubling.cards {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
</style>
