import _ from "lodash";
import fitty from "fitty";

function fitMultiple(elements, fittyOptions) {
  const sortedElements = _.orderBy(elements, el => parseInt(el.innerHTML, 10), [
    "desc"
  ]);
  const others = _.drop(sortedElements, 1);
  const toFit = sortedElements[0];

  if (toFit) {
    toFit.addEventListener("fit", e => {
      _.each(others, el => {
        el.style.fontSize = `${e.detail.newValue}px`;
      });
    });
    fitty(toFit, fittyOptions);
  }
}

export default fitMultiple;
