<template lang="html">
  <div :class="{ filtered }">
    <dropdown
      v-if="deliverableTypes.length > 1"
      name="Channel"
      :items="deliverableTypesAsOptions()"
      @change="change"
      :multiple="true"
      :include-blank="false"
      class="menu-drop labeled icon button pink floating"
    >
      <i class="icon filter" slot="menuIcon"></i>
      <span class="text" slot="menuName">Filter</span>
      <span slot="label" slot-scope="props">
        <i
          class="ui icon"
          :class="props.item.iconClass"
          :style="{ color: colors[props.item.value] || colors.other }"
        ></i>
        {{ props.item.label | humanize }}
        <a class="ui label circular">
          {{ typeIndex[props.item.value] }}
        </a>
      </span>
    </dropdown>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Dropdown from "components/dropdown.vue";
import _ from "lodash";

export default {
  components: { Dropdown },
  props: ["deliverableTypes", "typeIndex", "colors"],
  data() {
    return {
      filtered: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    change(value) {
      this.filtered = value !== "";
      this.$emit("change", value);
    },
    deliverableTypesAsOptions() {
      const $this = this;
      const opts = this.deliverableTypes.map((t) => {
        if (t.type == "youtube") {
          t.label = "YouTube";
        }
        return {
          label: t.label,
          iconClass: t.iconClass,
          value: t.type,
        };
      });

      return _.sortBy(opts, (del) => $this.typeIndex[del.label] * -1);
    },
  },
};
</script>

<style lang="css"></style>
