<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div
      class="vqb-group"
      :class="[{ 'ui segment': depth > 1 }, 'depth-' + depth.toString()]"
    >
      <a
        v-if="depth > 1 && query.children.length < 1"
        @click.stop.prevent="remove"
        class="ui circular icon label white top right floating"
      >
        <i class="icon minus fitted"></i>
      </a>

      <div class="margin-y-small">
        <query-builder-children v-bind="$props" />
      </div>

      <div class="ui form vqb-group-body content">
        <div class="fields" v-if="depth > 1">
          <div class="five wide field">
            <select
              v-model="selectedRule"
              class="ui dropdown search selection no-js"
            >
              <option value="_blank">Add a condition</option>
              <option v-for="rule in rules" :key="rule.id" :value="rule">
                {{ rule.label }}
              </option>
            </select>
          </div>

          <!--           <div class="field">
                        <button type="button" class="ui secondary button" @click="addRule">
              {{ labels.addRule }}
            </button>

          </div>
 -->
          <!--           <div v-if="query.children.length > 1" class="field">
            <select
              id="vqb-match-type"
              v-model="query.logicalOperator"
              class="ui dropdown selection no-js"
            >
              <option
                v-for="label in labels.matchTypes"
                :key="label.id"
                :value="label.id"
              >
                {{ label.label }}
              </option>
            </select>
          </div> -->
        </div>
      </div>
      <a v-if="depth < maxDepth" @click="addGroup" class="pointer">
        <i class="icon plus"></i>
        Add Filters
      </a>
    </div>

    <div class="margin-y-small" v-if="showCombinatorSelection">
      <div class="ui compact simple dropdown label small">
        {{ selectedLogicalOperator }}
        <i
          class="icon caret down fitted margin-left-tiny margin-right-zero"
        ></i>
        <div class="menu">
          <div
            v-for="label in labels.matchTypes"
            :key="label.id"
            :value="label.id"
            class="item"
            @click="selectCombinator(label.id)"
          >
            {{ label.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderGroup from "vue-query-builder/dist/group/QueryBuilderGroup.umd.js";
import FuiRule from "./FuiRule.vue";

export default {
  name: "QueryBuilderGroup",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    QueryBuilderRule: FuiRule,
  },
  extends: QueryBuilderGroup,
  computed: {
    selectedLogicalOperator: {
      get() {
        return this.labels.matchTypes.find(
          ({ id }) => id === this.$parent.query.logicalOperator
        ).id;
      },
      set(val) {
        this.$parent.query.logicalOperator = val;
      },
    },
    showCombinatorSelection() {
      return (
        this.index + 1 < this.$parent.query.children.length && this.depth > 1
      );
    },
  },
  watch: {
    selectedRule(val, oldVal) {
      if (val !== "_blank" && val !== oldVal) {
        this.addRule();
        this.resetSelectedRule();
      }
    },
    "query.children": function (val, oldVal) {
      if (val.length == 0 && oldVal.length > 0) {
        // console.log("we need to remove this group now");
      }
    },
  },
  mounted() {
    this.resetSelectedRule();
  },

  methods: {
    selectCombinator(combinator) {
      this.selectedLogicalOperator = combinator;
    },
    resetSelectedRule() {
      this.selectedRule = "_blank";
    },
  },
};
</script>

<style>
.vqb-group .icon.white {
  color: #fff;
}

.vue-query-builder .vqb-group .rule-actions {
  margin-bottom: 20px;
}
.vue-query-builder .vqb-rule {
  margin-top: 15px;
  margin-bottom: 15px;
  /*background-color: #f5f5f5;*/
  border-color: #ddd;
  padding: 15px;
}
.vue-query-builder .vqb-group.depth-1 .vqb-rule,
.vue-query-builder .vqb-group.depth-2 {
  /*  border-left: 2px solid #f4806f;*/
}
.vue-query-builder .vqb-group.depth-2 .vqb-rule,
.vue-query-builder .vqb-group.depth-3 {
  /*border-left: 2px solid #e5b586;*/
}
.vue-query-builder .vqb-group.depth-3 .vqb-rule,
.vue-query-builder .vqb-group.depth-4 {
  /*border-left: 2px solid #a1bcb1;*/
}
.vue-query-builder .close {
  opacity: 1;
  color: rgb(150, 150, 150);
}
@media (min-width: 768px) {
  .vue-query-builder .vqb-rule.form-inline .form-group {
    display: block;
  }
}
</style>
