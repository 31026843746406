<template lang="html">
  <div class="graphs">
    <div v-if="linechart" class="ui segment">
      <h3 class="header">Performance over Time</h3>
      <line-chart
        :stacked="true"
        :library="lineChartOptions"
        :data="lineChartDataUrl"
      />
      <div class="ui top right attached label">
        <div class="ui buttons tiny compact">
          <div class="ui button" @click="setLineChartRange([6, 'months'])">
            6 months
          </div>
          <div class="ui button" @click="setLineChartRange([3, 'months'])">
            3 months
          </div>
          <div class="ui button" @click="setLineChartRange([1, 'week'])">
            1 week
          </div>
        </div>
      </div>
    </div>

    <div class="ui ui equal width stackable grid">
      <div
        v-for="t in ['impressions', 'views', 'engagements', 'revenue']"
        v-if="sumStatisticType(t) > 0"
        class="column"
      >
        <div
          class="ui hovering fluid raised card padding-x-medium pointer padding-top-small"
        >
          <div
            v-if="sumStatisticType(t) > 0"
            class="wrap-graph centered fpo"
            :class="t"
          >
            <div class="pieChartCenter">
              <div class="value">
                <div class="fit">
                  {{ sumStatisticType(t) | abbreviateNumber(10000000) }}
                </div>
              </div>
              <div class="label">
                <div class="fit">
                  {{ t }}
                </div>
              </div>
            </div>
            <pie-chart
              :ref="'chart-' + t"
              class="piechart"
              height="400px"
              :legend="false"
              :library="pieChartOptions"
              :donut="true"
              :data="chartData(t)"
              :colors="chartColors(t)"
            />
          </div>
          <div
            v-if="chartData(t)"
            class="ui horizontal list center aligned pie-chart-data"
          >
            <div
              v-for="(value, label, i) in chartData(t)"
              class="item"
              :class="{ labelDisabled: disabledDatasets[`${t}-${label}`] }"
              @click.stop.prevent="legendClick(t, label)"
            >
              <span
                class="ui empty circular label"
                :sstyle="'background-color: ' + colors[label]"
                :style="'background-color: ' + chartColors(t)[i]"
              />
              {{ label }}
            </div>
          </div>
          <div
            class="ui center aligned text hint margin-bottom-medium margin-top-small"
          >
            <strong class="capitalize">{{ t }}:</strong>
            <span v-html="metricTypeHint(t)" />
          </div>
        </div>
      </div>
    </div>

    <div class="ui equal width grid" />

    <div
      v-if="youTubeDeliverables.length > 0"
      class="youtubeChart ui centered grid padding-x-medium pointer padding-y-medium"
    >
      <!-- <bar-chart
        height="150px"
        :data="youTubeData"
        :colors="[colors['Youtube'], '#ccc']"
        legend="bottom"
      ></bar-chart> -->

      <div class="ui statistics centered pieChartCenter">
        <div
          v-for="obj in youTubeData"
          class="ui statistic card padding-xy-small"
        >
          <div class="value">
            {{ obj.data[0][1] | abbreviateNumber(10000000) }}
          </div>
          <div class="label">
            <span style="text-transform: none !important">YouTube </span>
            {{ obj.name }}
          </div>
        </div>
      </div>

      <br />
    </div>
    <div
      class="ui centered text hint margin-bottom-medium margin-top-small"
      v-if="youTubeDeliverables.length > 0"
    >
      These metrics are not aggregated alongside other content channels (<a
        href="http://help.influencekit.com/en/articles/2138024-what-metrics-are-available-on-reports-and-what-do-they-mean"
        target="_blank"
        >learn why</a
      >)
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Chartkick from "chartkick";
import VueChartkick from "vue-chartkick";
import fitMultiple from "functions/fitMultiple";
import fitty from "fitty";
import _ from "lodash";

const { moment } = window;

Vue.use(VueChartkick, { Chartkick });

export default {
  components: {},
  props: {
    deliverables: Array,
    linechart: Boolean,
    colors: {
      type: Object,
    },
  },
  data() {
    return {
      pieChartOptions: {
        // animation: false,
        border: false,
        cutoutPercentage: 80,
        // animation: {
        //   animateRotate: true
        // },
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            fontSize: 16,
            fontColor: "#545454",
            fontFamily: "Work Sans",
            fontStyle: "500",
          },
        },
      },
      lineChartOptions: {
        spanGaps: true,
        elements: {
          line: {
            tension: 0.05,
            fill: false,
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem) {
              return parseInt(tooltipItem.yLabel, 10).toLocaleString();
            },
          },
        },
        scales: {
          yAxes: [
            {
              id: "y-axis-engagements",
              position: "left",
              type: "linear",
              ticks: {
                min: 0,
                callback(value) {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: "y-axis-impressions",
              position: "right",
              type: "linear",
              ticks: {
                min: 0,
                callback(value) {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
      },
      lineChartStartDate: moment().subtract(6, "months").format(),
      lineChartEndDate: moment().format(),
      disabledDatasets: [],
    };
  },
  computed: {
    deliverablesExcludingYouTube() {
      return this.deliverables.filter((d) => d.inferred_type != "youtube");
    },
    youTubeDeliverables() {
      return this.deliverables.filter((d) => d.inferred_type == "youtube");
    },
    youTubeData() {
      const $this = this;
      let data = [];
      ["impressions", "views", "engagements"].forEach(function (metric_type) {
        let sum = 0;

        $this.youTubeDeliverables.forEach((d) => {
          d.statisticsValues.forEach((s) => {
            if (s.type !== metric_type) {
              return;
            }
            sum += s.total.value;
          });
        });
        if (sum > 0) {
          let metric_type_label =
            metric_type == "engagements" ? "Likes & Comments" : metric_type;
          data.push({
            name: metric_type_label.toUpperCase(),
            data: [["YouTube", sum]],
          });
        }
      });
      return data;
    },
    deliverableIds() {
      if (this.deliverables.length > 0) {
        return _.map(this.deliverables, "id");
      }
      return [];
    },
    lineChartDataUrl() {
      return `/statistics?deliverable_ids=${this.deliverableIds.join(
        ","
      )}&startDate=${this.lineChartStartDate || ""}&endDate=${
        this.lineChartEndDate || ""
      }`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fitChartValues();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.fitChartValues();
    });
  },
  methods: {
    legendClick(type, label) {
      const chart = this.chartObject(type);
      const index = _.indexOf(chart.data.labels, label);

      var i, ilen, meta;

      for (i = 0, ilen = (chart.data.datasets || []).length; i < ilen; ++i) {
        meta = chart.getDatasetMeta(i);
        // console.log(meta);
        // toggle visibility of index if exists

        if (meta.data[index]) {
          meta.data[index].hidden = !meta.data[index].hidden;

          if (meta.data[index].hidden) {
            this.$set(this.disabledDatasets, `${type}-${label}`, true);
          } else {
            this.$delete(this.disabledDatasets, `${type}-${label}`);
          }
        }
      }

      chart.update();
    },
    chartObject(t) {
      if (this.$refs["chart-" + t] && this.$refs["chart-" + t][0]) {
        return this.$refs["chart-" + t][0].chart.chart;
      } else {
        return null;
      }
    },
    metricTypeHint(type) {
      switch (type) {
        case "impressions":
          return "The number of times your content was visible to users";
        case "views":
          return "The number of times users read or watched your content";
        case "engagements":
          return "The number of times users interacted with your content";
        case "revenue":
          return "The amount of revenue attributed to this campaign";
      }
    },
    sumStatisticType(t) {
      let i = 0;
      const data = this.chartData(t);

      Object.keys(data).forEach((k) => {
        i += data[k];
      });
      return i;
    },
    chartColors(type) {
      const r = [];
      Object.keys(this.chartData(type)).forEach((k) => {
        if (k.includes("Other")) {
          r.push(this.colors["Other"]);
        } else {
          r.push(this.colors[k]);
        }
      });
      return r;
    },
    chartData(type) {
      const data = {};
      this.deliverablesExcludingYouTube.forEach((d) => {
        if (!d.statisticsValues) {
          return;
        }
        let sum = 0;

        d.statisticsValues.forEach((s) => {
          if (s.type !== type) {
            return;
          }
          sum += s.total.value;
        });

        if (d.inferred_type == "other") {
          d.inferred_type = `Other (${d.name})`;
        }

        if (data[d.inferred_type]) {
          data[d.inferred_type] += sum;
        } else {
          data[d.inferred_type] = sum;
        }
      });
      const filteredData = {};
      Object.keys(data).forEach((dataType) => {
        if (data[dataType] > 0) {
          filteredData[this.typeAsLabel(dataType)] = data[dataType];
        }
      });
      return filteredData;
    },
    typeAsLabel(type) {
      switch (type) {
        case "url":
          return "Articles";
        case "instagram_stories":
          return "Instagram Stories";
        case "one_signal":
          return "Push Notification (OneSignal)";
        case "push_engage":
          return "Push Notification (PushEngage)";
        case "google":
          return "Articles";
        case "omny_studio":
          return "Podcast (Omny Studio)";
        case "tik_tok":
          return "TikTok";
        case "youtube":
          return "YouTube";
        case "constantcontact":
          return "Constant Contact";
        case "campaignmonitor":
          return "Campaign Monitor";
        case "short_io":
          return "Short.io";
        default:
          return type[0].toUpperCase() + type.slice(1);
      }
    },
    fitChartValues() {
      fitMultiple(this.$el.querySelectorAll(".graphs .column .value .fit"), {
        minSize: 40,
        maxSize: 100,
      });

      fitty(".graphs .pieChartCenter .label .fit", {
        maxSize: 30,
        minSize: 20,
      });
    },
    setLineChartRange(args) {
      this.lineChartStartDate = moment()
        .subtract(...args)
        .format();
      this.lineChartEndDate = moment().format();
    },
  },
};
</script>

<style lang="scss" scoped>
.graphs .pieChartCenter,
.pieChartCenter .ui.statistics .statistic {
  font-family: lato, helvetica neue, Arial, Helvetica, sans-serif;
  color: #1b1c1d;
  text-transform: uppercase;
  text-align: center;
  // max-width: 60% !important;

  .value,
  > .value {
    font-family: lato, helvetica neue, Arial, Helvetica, sans-serif;
    max-width: 100% !important;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .label,
  > .label {
  }
}

.ui.horizontal.list .item {
  user-select: none;
}
.item.labelDisabled {
  opacity: 0.65;
  text-decoration: line-through;
}

.piechart {
  z-index: 3;
}

@media (max-width: 768px) {
  .graphs .piechart {
    height: 250px !important;
  }
  .graphs .pie-chart-data {
    margin-top: 16px;
  }
}
</style>
