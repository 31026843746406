import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import DeliverablesReportView from "views/deliverables/report.vue";
import SuiVue from "semantic-ui-vue";

import Chartkick from "chartkick";
import VueChartkick from "vue-chartkick";
import VueRouter from "vue-router";

Vue.use(VueChartkick, { Chartkick });
Vue.use(VueRouter);
Vue.use(SuiVue);

document.addEventListener("turbo:load", function () {
  if (document.querySelector("#deliverables-report")) {
    window.deliverablesReportView = new Vue({
      el: "#deliverables-report",
      components: {
        "deliverables-report-view": DeliverablesReportView,
      },
    });
  }
});
