<template>
  <!-- eslint-disable vue/no-v-html -->
  <div :key="`${rule.id}-${depth}`">
    <div v-if="showCombinatorSelection">
      <!--       <select
        v-model="selectedLogicalOperator"
        class="ui compact dropdown selection no-js"
      >
        <option
          v-for="combinator in labels.matchTypes"
          :key="combinator.id"
          :value="combinator.id"
        >
          {{ labelForCombinator(combinator) }}
        </option>
      </select> -->

      <div class="ui compact simple dropdown label">
        {{ labelForCombinator(selectedLogicalOperator) }}
        <i
          class="icon caret down fitted margin-left-tiny margin-right-zero"
        ></i>
        <div class="menu">
          <div
            v-for="combinator in labels.matchTypes"
            :key="combinator.id"
            :value="combinator.id"
            class="item"
            @click="selectCombinator(combinator.id)"
          >
            {{ labelForCombinator(combinator.id) }}
          </div>
        </div>
      </div>

      of the following conditions
    </div>

    <div class="vqb-rule ui form margin-y-zero">
      <div class="ui very compact grid padding-x-zero middle aligned">
        <div class="ui two wide column">
          <div
            v-if="
              typeof rule.operands == 'undefined' || rule.operands.length == 1
            "
          >
            <i v-if="rule.icon" :class="rule.icon" class="icon"></i>
            {{ rule.label }}
          </div>

          <!-- List of operands (optional) -->
          <div
            v-if="
              typeof rule.operands !== 'undefined' && rule.operands.length > 1
            "
          >
            <select
              v-model="query.operand"
              class="ui dropdown selection no-js nowrap fluid"
              placeholder="Select one ..."
            >
              <option
                v-for="operand in rule.operands"
                :key="operand"
                :value="operand"
              >
                {{ rule.operandLabels[operand] }}
              </option>
            </select>
          </div>
        </div>

        <div class="ui four wide column">
          <!-- List of operators (e.g. =, !=, >, <) -->
          <select
            v-if="typeof rule.operators !== 'undefined'"
            v-model="query.operator"
            :class="{ disabled: rule.operators.length < 2 }"
            class="ui dropdown selection no-js"
          >
            <option
              v-for="operator in rule.operators"
              :key="operator"
              :value="operator"
            >
              {{ operatorValueAsLabel(operator) }}
            </option>
          </select>
        </div>

        <div class="ui nine wide column">
          <!-- Basic text input -->
          <input
            v-if="rule.inputType === 'text'"
            v-model="query.value"
            v-show="!['blank', 'present'].includes(query.operator)"
            class="input"
            type="text"
            :placeholder="labels.textInputPlaceholder"
          />

          <!-- Basic number input -->
          <input
            v-if="rule.inputType === 'number'"
            v-model="query.value"
            class="number input"
            type="number"
          />

          <!-- Datepicker -->
          <input
            v-if="rule.inputType === 'date'"
            v-model="query.value"
            class="input"
            type="date"
          />

          <!-- Custom component input -->
          <div v-if="isCustomComponent" class="vqb-custom-component-wrap">
            <component
              :is="rule.component"
              :value="query.value"
              @input="updateQuery"
              class="ten wide"
            />
          </div>

          <!-- Checkbox input -->
          <template v-if="rule.inputType === 'checkbox'">
            <div
              v-for="choice in rule.choices"
              :key="choice.value"
              class="field"
            >
              <div class="ui checkbox">
                <input
                  :id="
                    'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                  "
                  v-model="query.value"
                  type="checkbox"
                  :value="choice.value"
                  class="hidden"
                />
                <label
                  class=""
                  :for="
                    'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                  "
                  v-html="choice.label"
                >
                </label>
              </div>
            </div>
          </template>

          <!-- Radio input -->
          <template v-if="rule.inputType === 'radio'">
            <div
              v-for="choice in rule.choices"
              :key="choice.value"
              class="ui radio checkbox"
            >
              <input
                :id="
                  'depth' +
                  depth +
                  '-' +
                  rule.id +
                  '-' +
                  index +
                  '-' +
                  choice.value
                "
                v-model="query.value"
                :name="'depth' + depth + '-' + rule.id + '-' + index"
                type="radio"
                :value="choice.value"
                class="hidden"
              />
              <label
                :for="
                  'depth' +
                  depth +
                  '-' +
                  rule.id +
                  '-' +
                  index +
                  '-' +
                  choice.value
                "
              >
                {{ choice.label }}
              </label>
            </div>
          </template>

          <!-- Select without groups -->
          <div
            v-if="
              rule.inputType === 'select' &&
              !['blank', 'present'].includes(query.operator)
            "
            class="field"
          >
            <select
              v-if="rule.inputType === 'select' && !hasOptionGroups"
              v-model="query.value"
              :data-placeholder="`Type a ${rule.label}`"
              class="ui dropdown search selection"
              :class="{ multiple: rule.type === 'multi-select' }"
              :multiple="rule.type === 'multi-select'"
            >
              <option
                v-for="option in selectOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <!-- Select with groups -->
          <select
            v-if="rule.inputType === 'select' && hasOptionGroups"
            v-model="query.value"
            class="ui dropdown selection no-js"
            :multiple="rule.type === 'multi-select'"
          >
            <optgroup
              v-for="(option, option_key) in selectOptions"
              :key="option_key"
              :label="option_key"
            >
              <option
                v-for="sub_option in option"
                :key="sub_option.value"
                :value="sub_option.value"
              >
                {{ sub_option.label }}
              </option>
            </optgroup>
          </select>
        </div>

        <div class="one wide column">
          <!-- Remove rule button -->
          <a @click.stop.prevent="remove" class="ui circular icon label white">
            <i class="icon minus fitted"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from "vue-query-builder/dist/rule/QueryBuilderRule.umd.js";

const $ = window.jQuery;

export default {
  extends: QueryBuilderRule,
  computed: {
    selectedLogicalOperator: {
      get() {
        return this.labels.matchTypes.find(
          ({ id }) => id === this.$parent.query.logicalOperator
        ).id;
      },
      set(val) {
        this.$parent.query.logicalOperator = val;
      },
    },
    showCombinatorSelection() {
      return this.index == 0 && this.$parent.query.children.length > 1;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDropdowns();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.initDropdowns();
    });
  },
  methods: {
    selectCombinator(combinator) {
      this.selectedLogicalOperator = combinator;
    },
    labelForCombinator(combinator) {
      let label = combinator;
      switch (combinator) {
        case "and":
          label = "All";
          break;
        case "or":
          label = "Any";
          break;
      }
      return label;
    },

    operatorValueAsLabel(value) {
      if (!value) return "";
      let label = value;
      switch (value) {
        case "in":
          label = "Is one of";
          break;
        case "not_in":
          label = "Is not one of";
          break;
        case "i_cont":
          label = "Contains";
          break;
        case "not_i_cont":
          label = "Doesn't contain";
          break;
        case "i_cont_any":
          label = "Contains any of";
          break;
        case "not_i_cont_any":
          label = "Doesn't contain";
          break;
        case "date_between":
          label = "Is between";
          break;
        case "gt":
          label = "Is greater than";
          break;
        case "lt":
          label = "Is less than";
          break;
        case "eq":
          label = "equals";
          break;
        case "not_eq":
          label = "Is not equal to";
          break;
        case "present":
          label = "Is not blank";
          break;
        case "blank":
          label = "Is blank";
          break;
      }

      return label;
    },
    initDropdowns() {
      $(this.$el)
        .find(".dropdown.selection:not(.no-js)")
        .each((i, element) => {
          if (!$(element).data("dropdown-loaded")) {
            $(element)
              .dropdown({
                fullTextSearch: true,
                clearable: true,
                allowAdditions: this.rule.allowAddition,
                placeholder: $(element).data("placeholder") || "auto",
              })
              .closest(".dropdown")
              .data("dropdown-loaded", true);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.vqb-rule {
  .vqb-custom-component-wrap {
    .field.ten.wide {
      width: 100% !important;
      .two.fields {
        margin-bottom: 0;
      }
    }
  }

  .icon.minus {
    color: #fff;
  }

  label .dropdown.selection {
    width: auto !important;
  }
}

.dropdown.selection.no-js {
  padding: 0.5em !important;
}
</style>
