<template lang="html">
  <div class="ui container grid">
    <div class="eight wide computer sixteen wide mobile column column-filter">
      <report-filter
        :deliverable-types="deliverableTypes"
        :type-index="typeIndex"
        :selected-deliverable-types="selectedDeliverableTypes"
        :colors="colors"
        @change="reportFilterChanged"
      />
    </div>
    <div class="eight wide computer sixteen wide mobile column right aligned">
      <div class="ui five wide action input" :class="{ loading: isLoading }">
        <input
          v-model="query"
          type="text"
          class="prompt"
          placeholder="Search"
        />
        <button class="ui icon button">
          <i class="search icon" />
        </button>
      </div>

      <!--       <a
        v-if="accessToken"
        :href="getUpdatesPath"
        data-remote="true"
        title="Get Report Updates"
        class="ui small primary icon button margin-left-small"
      >
        <i class="icon bell bell-ring" />
        Get Report Updates
      </a> -->
    </div>
  </div>
</template>

<script>
import ReportFilter from "./filter.vue";
import { mapState } from "vuex";

export default {
  components: {
    ReportFilter,
  },
  props: [
    "deliverableTypes",
    "selectedDeliverableTypes",
    "typeIndex",
    "colors",
  ],
  data() {
    return {
      isLoading: false,
      query: "",
    };
  },
  computed: {
    ...mapState("report", {
      report: "report",
      accessToken: "accessToken",
    }),
    // getUpdatesPath() {
    //   return `/reports/${this.report.id}/get_updates.js`;
    // },
  },
  watch: {
    query(val) {
      this.$emit("changeSearchQuery", val);
    },
  },
  methods: {
    reportFilterChanged(value) {
      this.$emit("reportFilterChanged", value);
    },
  },
};
</script>

<style lang="css"></style>
