<script>
import Vue from "vue/dist/vue.esm";
// import VueRouter from "vue-router";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import VueSticky from "vue-sticky";
import ReportSettings from "views/reports/settings.vue";
import ReportCards from "./components/ReportCards.vue";
// import ReportTable from "./components/ReportTable.vue";
import ReportCharts from "./components/ReportCharts.vue";
import ReportHeader from "./components/header.vue";
import ReportMenu from "./components/reportMenu.vue";

// Vue.use(VueRouter);

// const routes = [
//   { path: "/cards", component: ReportCards, props: {} },
//   { path: "/table", component: ReportTable, props: {} },
//   { path: "*", redirect: "/cards" }
// ];
// const router = new VueRouter({ routes, linkActiveClass: "active" });

export default {
  components: {
    ReportCards,
    // ReportTable,
    ReportCharts,
    ReportHeader,
    ReportMenu,
    ReportSettings,
  },
  directives: {
    sticky: VueSticky,
  },
  props: {
    resource: Object,
    report: Object,
    resourcePath: String,
    tenantLogo: String,
    linechart: Boolean,
    deliverables: Array,
    editable: false,
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    expandStories: {
      type: Boolean,
      default: false,
    },
    pdfReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeIndex: {},
      selectedDeliverableTypes: [],
      searchQuery: "",
    };
  },
  computed: {
    ...mapState("report", [
      "loading",
      "loaded",
      "report.sections.removeDuplicates",
    ]),
    ...mapGetters("report", ["hasFilters"]),
    ...mapGetters("deliverable", {
      typeAsIconClass: "inferredTypeAsIconClass",
    }),
    ...mapState("deliverable", ["colors"]),

    lastUpdated() {
      if (this.sortedDeliverables.length > 0) {
        const lastFetched = _.compact(
          _.map(this.sortedDeliverables, "last_fetched")
        );
        const date = _.reverse(lastFetched.sort())[0];

        if (date) {
          return moment(date).format("MM/DD/Y hh:mm");
        }
        return false;
      }
      return "";
    },
    filteredDeliverables() {
      const that = this;
      return this.deliverables.filter((d) => that.shouldShow(d));
    },
    sortedDeliverables() {
      const $this = this;
      let dels = this.filteredDeliverables;

      if ($this.report && $this.report.sections.removeDuplicates) {
        const deliverablesMap = new Map();

        this.filteredDeliverables.forEach((del) => {
          const existing = deliverablesMap.get(del.url);
          if (
            !existing ||
            new Date(del.created_at) > new Date(existing.created_at)
          ) {
            deliverablesMap.set(del.url, del);
          }
          dels = Array.from(deliverablesMap.values());
        });
      }

      return _.sortBy(dels, (del) => del.sortableStatisticValue.value * -1).map(
        (del) => {
          del.iconClass = $this.typeAsIconClass(del.inferred_type);
          return del;
        }
      );
      // return _.sortBy(
      //   this.filteredDeliverables,
      //   (del) => del.sortableStatisticValue.value * -1
      // ).map((del) => {
      //   del.iconClass = $this.typeAsIconClass(del.inferred_type);
      //   return del;
      // });
    },
    deliverableTypes() {
      const that = this;
      that.typeIndex = {};
      const a = this.deliverables.map((d) => {
        const base = that.typeIndex;
        const type = d.inferred_type;
        if (!base[type]) {
          base[type] = 0;
        }
        that.typeIndex[type] += 1;
        return type;
      });
      return Array.from(new Set(a)).map((type) => ({
        type,
        iconClass: this.typeAsIconClass(type),
        label: this.typeAsLabel(type),
      }));
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.$store.dispatch("report/loadDeliverables");
    });
  },
  methods: {
    setSearchQuery(val) {
      this.searchQuery = val;
    },
    deliverableTypesChanged(value) {
      this.selectedDeliverableTypes = value.split(",").filter((e) => e);
    },
    // typeAsIconClass(type) {
    //   switch (type) {
    //     case "url":
    //       return "linkify";
    //     case "bitly":
    //       return "linkify";
    //     case "mailchimp":
    //     case "convertkit":
    //     case "sendfox":
    //     case "aweber":
    //     case "activecampaign":
    //       return "envelope";
    //     case "other":
    //       return "sticky note outline";
    //     case "instagram_stories":
    //       return "instagram";
    //     case "push_engage":
    //       return "bullhorn";
    //     default:
    //       return type;
    //   }
    // },
    typeAsLabel(type) {
      switch (type) {
        case "url":
          return "Articles";
        case "instagram_stories":
          return "Instagram Stories";
        case "one_signal":
          return "Push Notification (OneSignal)";
        case "push_engage":
          return "Push Notification (PushEngage)";

        default:
          return type[0].toUpperCase() + type.slice(1);
      }
    },
    shouldShow(deliverable) {
      const q = this.searchQuery.toLowerCase();
      return (
        (this.selectedDeliverableTypes.length < 1 ||
          this.selectedDeliverableTypes.includes(
            deliverable.inferred_type.toString()
          )) &&
        (q === "" ||
          String(deliverable.name).toLowerCase().indexOf(q) > -1 ||
          String(deliverable.description).toLowerCase().indexOf(q) > -1 ||
          String(deliverable.user.login).toLowerCase().indexOf(q) > -1 ||
          String(deliverable.url).toLowerCase().indexOf(q) > -1)
      );
    },
  },
};
</script>

<template>
  <div class="ui container">
    <report-settings v-if="editable && !report.reportable_id" />

    <report-header
      :key="report.id"
      :last-updated="lastUpdated"
      :resource="resource"
      :initial-report="report"
      :resource-path="resourcePath"
      :initial-tenant-logo="tenantLogo"
      :editable="editable"
      :deliverable-types="deliverableTypes"
      :type-index="typeIndex"
      :colors="colors"
      @reportFilterChanged="deliverableTypesChanged"
    />

    <div v-if="loading" class="ui flat segment" style="height: 400px">
      <div class="ui active inverted dimmer">
        <div class="ui text loader">Building report</div>
      </div>
      <p />
    </div>

    <div v-if="!loading" class="ui report-main">
      <div v-if="deliverables.length < 1" class="ui message">
        <div
          v-if="!hasFilters || (hasFilters && !loaded)"
          class="ui basic center aligned segment"
        >
          After applying some filters, your report will be generated here.
        </div>
        <div v-else>
          <div class="header">Sorry!</div>
          <p>No results were found.</p>
        </div>
      </div>

      <report-charts
        v-if="deliverables.length > 0"
        :deliverables="filteredDeliverables"
        :colors="colors"
        :linechart="linechart"
      />

      <!-- ui bound sticky -->
      <div
        v-if="deliverables.length > 0"
        class="ui menu-bottom desktop-only"
        :class="{
          sticky: !isMobile,
          'sticky-filter-search': !isMobile,
        }"
      >
        <report-menu
          :deliverable-types="deliverableTypes"
          :selected-deliverable-types="selectedDeliverableTypes"
          :type-index="typeIndex"
          :colors="colors"
          @reportFilterChanged="deliverableTypesChanged"
          @changeSearchQuery="setSearchQuery"
        />
      </div>

      <keep-alive>
        <report-cards
          :deliverables="this.sortedDeliverables"
          :lazy-load="lazyLoad"
          :expand-stories="expandStories"
          :pdf-report="pdfReport"
        />
      </keep-alive>
    </div>
  </div>
</template>

<style lang="css" scoped>
.sticky-filter-search {
  position: sticky !important;
  top: 0px;
  z-index: 1000;
}
</style>
