<template lang="html">
  <div>
    <!-- <pre>{{ JSON.stringify(queryObject, null, 2) }}</pre> -->

    <div class="ui clearing padded segment">
      <div v-if="errors" class="ui error message visible">
        <div class="ui relaxed list">
          <div v-for="(error, key) in errors" class="item">
            <span class="capitalize">
              {{ key }}
            </span>
            {{ error.join(", ") }}
          </div>
        </div>
      </div>

      <h2 class="ui header">
        <div class="ui right floated">
          <div
            class="ui button"
            :class="{ disabled: loaded, loading: loading }"
            @click.stop.prevent="loadDeliverables"
          >
            Apply Filters
          </div>

          <div
            class="ui icon labeled primary button"
            :class="{ disabled: saved }"
            @click.stop.prevent="save"
          >
            <i class="folder icon" />
            Save
          </div>
        </div>

        Report Builder
        <div class="sub header pointer" @click="toggleFiltersExpanded">
          <i
            class="icon angle fitted"
            :class="{ right: !filtersExpanded, down: filtersExpanded }"
          />
          Select filters to build your report
          <span v-if="!filtersExpanded" class="ui text small"
            >(click to show filters)</span
          >
        </div>
      </h2>

      <vue-query-builder
        v-if="!hasFilters"
        v-show="filtersExpanded"
        v-model="report.sections.queryObject"
        :rules="rules"
        :max-depth="2"
        :labels="queryBuilderLabels"
      >
        <template v-slot:default="slotProps">
          <fui-group
            v-bind="slotProps"
            :query.sync="report.sections.queryObject"
          />
          <div class="ui horizontal divider" />
        </template>
      </vue-query-builder>

      <div v-if="report">
        <div class="ui checkbox input">
          <input type="checkbox" v-model="report.sections.removeDuplicates" />
          <label for="removeDuplicates">Remove duplicates</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue/dist/vue.esm";
import VueQueryBuilder from "vue-query-builder";
import FuiGroup from "components/queryBuilder/FuiGroup.vue";
import DateRangeRule from "components/queryBuilder/DateRangeRule.vue";
import Toasted from "vue-toasted";
import { mapState, mapGetters } from "vuex";
import store from "store";

const { $ } = window;

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
});

export default {
  components: {
    VueQueryBuilder,
    FuiGroup,
    DateRangeRule,
  },
  store,
  props: {},
  data() {
    return {
      filtersExpanded: true,
      providers: {
        instagram: {
          class: "instagram",
          label: "Instagram",
          value: "instagram",
        },
        instagram_stories: {
          class: "instagram-stories",
          label: "Instagram Stories",
          value: "instagram_stories",
        },
        facebook: { class: "facebook", label: "Facebook", value: "facebook" },
        youtube: { class: "youtube", label: "YouTube", value: "youtube" },
        twitter: { class: "twitter", label: "Twitter", value: "twitter" },
        url: { class: "google", label: "Blog Posts", value: "url" },
        // mailchimp: { class: "mailchimp", label: "Mailchimp" },
        tik_tok: { class: "tik-tok", label: "Tik Tok", value: "tik_tok" },
        email: {
          class: "envelope",
          label: "Email",
          value: "email",
        },
        pinterest: {
          class: "pinterest",
          label: "Pinterest",
          value: "pinterest",
        },
        bitly: { class: "bitly", label: "Bitly", value: "bitly" },
      },
      calendars: [],
      tags: [],
      users: [],
      sharedReports: [],
      assignments: [],
      partnerCampaigns: [],
    };
  },
  computed: {
    ...mapState("report", {
      errors: "errors",
      report: "report",
      saving: "saving",
      saved: "saved",
      loading: "loading",
      loaded: "loaded",
      deliverables: "deliverables",
    }),
    ...mapState("sponsor", {
      sponsors: "all",
    }),
    ...mapGetters("report", ["hasFilters", "filters", "queryObject"]),
    ...mapGetters("app", ["enabled"]),

    selectedCalendars() {
      return _.compact(
        _.map(this.report.sections.deliverables.filters.calendars, (id) =>
          _.find(this.calendars, { id })
        )
      );
    },
    queryBuilderLabels() {
      return {
        matchType: "Match Type",
        matchTypes: [
          { id: "and", label: "And" },
          { id: "or", label: "Or" },
        ],
        addRule: "Add Condition",
        removeRule: "&times;",
        addGroup: "Add Condition Group",
        removeGroup: "&times;",
        textInputPlaceholder: "Type a value",
      };
    },
    rules() {
      const multiSelectOperators = ["in", "not_in"];

      let defaultRules = [
        {
          type: "multi-select",
          id: "normalized_inferred_type",
          operands: ["normalized_inferred_type"],
          operators: multiSelectOperators,
          label: "Platform",
          icon: "share alternate",
          choices: this.providersAsOptions,
        },
        {
          type: "multi-select",
          id: "user_id",
          operands: ["user_id"],
          operators: multiSelectOperators,
          label: "User",
          icon: "users",
          choices: this.usersAsOptions,
        },
        {
          type: "multi-select",
          allowAddition: true,
          id: "url",
          operands: ["url"],
          operators: ["i_cont_any", "not_i_cont_any", "present", "blank"],
          label: "URL",
          icon: "linkify",
          choices: [],
        },
        {
          type: "custom-component",
          id: "provider_published_at",
          operands: ["provider_published_at"],
          operators: ["date_between"],
          icon: "calendar alternate",
          label: "Deliverable Published Date",
          component: DateRangeRule,
          default: 50,
        },
      ];

      let influencerAccountRules = [
        {
          type: "multi-select",
          id: "calendar_id",
          operands: ["calendar_id"],
          label: "Calendar",
          icon: "calendar",
          operators: multiSelectOperators,
          choices: this.calendarsAsOptions,
        },
        {
          type: "multi-select",
          id: "unscoped_event_event_category_id",
          operands: ["unscoped_event_event_category_id"],
          operators: multiSelectOperators,
          label: "Category",
          icon: "folder",
          choices: this.categoriesAsOptions,
        },
        {
          type: "multi-select",
          id: "unscoped_event_organization_id",
          operands: ["unscoped_event_organization_id"],
          operators: multiSelectOperators,
          label: "Sponsor",
          icon: "handshake",
          choices: this.sponsorsAsOptions,
        },
        {
          type: "multi-select",
          id: "unscoped_event_unscoped_tags_name",
          operands: ["unscoped_event_unscoped_tags_name"],
          operators: multiSelectOperators,
          label: "Tag",
          icon: "tag",
          choices: this.tagsAsOptions,
        },
        {
          type: "custom-component",
          id: "unscoped_event_starts_at",
          operands: ["unscoped_event_starts_at"],
          operators: ["date_between"],
          icon: "calendar alternate",
          label: "Assignment Date",
          component: DateRangeRule,
          default: 50,
        },
      ];

      const brandAccountRules = [
        {
          type: "text",
          allowAddition: true,
          id: "description",
          operands: ["description"],
          operators: ["i_cont", "not_i_cont", "present", "blank"],
          label: "Deliverable Description",
          icon: "align left",
        },
        {
          type: "text",
          allowAddition: true,
          id: "name",
          operands: ["name"],
          operators: ["i_cont", "not_i_cont", "present", "blank"],
          label: "Deliverable Label",
          icon: "align left",
        },
        {
          type: "custom-component",
          id: "unscoped_event_starts_at",
          operands: ["unscoped_event_starts_at"],
          operators: ["date_between"],
          icon: "calendar alternate",
          label: "Assignment Date",
          component: DateRangeRule,
          default: 50,
        },
        {
          type: "multi-select",
          allowAddition: false,
          id: "report_id",
          operands: ["report_id"],
          operators: multiSelectOperators,
          icon: "poll flipped",
          label: "Shared Report",
          choices: this.sharedReportsAsOptions,
        },
        {
          inputType: "number",
          id: "statistics_values",
          operands: [
            "total_statistics_value",
            "total_impressions_value",
            "total_engagements_value",
          ],
          operandLabels: {
            total_statistics_value: "Total Statistics",
            total_impressions_value: "Total Impressions",
            total_engagements_value: "Total Engagements",
          },
          operators: ["gt", "lt"],
          icon: "pie chart",
          label: "Statistics Values",
        },

        {
          type: "multi-select",
          allowAddition: false,
          id: "assignment_id",
          operands: ["assignment_id"],
          operators: multiSelectOperators,
          icon: "envelope-coin",
          label: "Assignment",
          choices: this.assignmentsAsOptions,
        },
        {
          type: "multi-select",
          allowAddition: false,
          id: "partner_campaign_id",
          operands: ["partner_campaign_id"],
          operators: multiSelectOperators,
          icon: "handshake",
          label: "Partner Campaign",
          choices: this.partnerCampaignsAsOptions,
        },
      ];

      if (this.enabled("brand_account")) {
        return [...defaultRules, ...brandAccountRules];
      } else {
        return [...defaultRules, ...influencerAccountRules];
      }
    },
    providersAsOptions() {
      return Object.values(this.providers);
    },
    categories() {
      return _.flatten(this.calendars.map((c) => c.event_categories));
    },
    assignmentsAsOptions() {
      return this.assignments.map((c) => ({
        label: c.title,
        value: c.id,
      }));
    },
    partnerCampaignsAsOptions() {
      return this.partnerCampaigns.map((c) => ({
        label: c.title,
        value: c.id,
      }));
    },
    sponsorsAsOptions() {
      return this.sponsors.map((c) => ({
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    calendarsAsOptions() {
      return this.calendars.map((c) => ({
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    tagsAsOptions() {
      return this.tags.map((c) => ({
        label: c.name,
        value: c.name,
      }));
    },
    categoriesAsOptions() {
      return _.flatten(
        this.categories.map((ec) => ({
          label: ec.name,
          value: ec.id,
          color: ec.color,
        }))
      );
    },
    usersAsOptions() {
      return _.flatten(
        this.users.map((u) => {
          const hash = {
            label: u.login,
            value: u.id,
          };

          if (u.tenant_id !== this.report.tenant_id) {
            hash.label = `${u.login} (${u.tenant_name})`;
          }

          return hash;
        })
      );
    },
    sharedReportsAsOptions() {
      return _.flatten(
        this.sharedReports.map((r) => ({
          label: `${r.name} (${r.username})`,
          value: r.id,
        }))
      );
    },
  },
  watch: {
    "report.sections.removeDuplicates": {
      handler() {
        this.save();
      },
      deep: true,
    },
    queryObject: {
      handler() {
        this.$store.dispatch("report/setSaved", false);
        this.$store.dispatch("report/setLoaded", false);
      },
      deep: true,
    },
    filters: {
      handler() {
        this.$store.dispatch("report/setSaved", false);
        this.$store.dispatch("report/setLoaded", false);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadCalendars();
    this.loadTags();
    this.loadUsers();
    this.loadSharedReports();
    this.loadAssignments();
    this.loadPartnerCampaigns();
    this.$store.dispatch("sponsor/loadAll");
  },
  methods: {
    toggleFiltersExpanded() {
      this.filtersExpanded = !this.filtersExpanded;
    },
    providerSelected(provider) {
      return _.includes(
        this.report.sections.deliverables.filters.providers,
        provider
      );
    },
    loadSharedReports() {
      $.read("/reports/shared_with_me.json").done((data) => {
        this.sharedReports = data;
      });
    },
    loadAssignments() {
      $.read("/influencer_hub/assignments.json").done((data) => {
        this.assignments = data;
      });
    },
    loadPartnerCampaigns() {
      $.read("/influencer_hub.json").done((data) => {
        this.partnerCampaigns = data;
      });
    },

    loadUsers() {
      $.read("/users.json").done((data) => {
        this.users = data;
      });
    },
    loadTags() {
      $.read("/tags.json").done((data) => {
        this.tags = data;
      });
    },
    loadCalendars() {
      const url = "/calendars.json";
      $.read(url).done((data) => {
        this.calendars = data;

        // maybe we don't need to require a calendar_id filter
        // if (this.calendars.length < 2) {
        //   this.report.sections.deliverables.filters.calendars.push(
        //     this.calendars[0].id
        //   );
        // }
      });
    },
    loadDeliverables() {
      this.$store.dispatch("report/loadDeliverables", this.queryObject);
    },
    save() {
      if (!this.loaded) {
        this.loadDeliverables();
      }
      this.$store
        .dispatch("report/save", this.report)
        .then(() => {
          Vue.toasted.success("Saved");
        })
        .catch((errors) => {
          Vue.toasted.error("Report was not saved");
        });
    },
  },
};
</script>

<style lang="css" scoped>
/* *:not(label) {
  font-weight: 400 !important
}

label {
  font-weight: 500 !important;
} */

.field .grouped.fields {
  max-height: 300px;
  overflow: auto;
}
</style>
