<template lang="html">
  <header class="head ui segment">
    <div v-if="!accessToken && showStatusMessage">
      <div class="ui right corner label error pointer">
        <span data-position="left center" :data-tooltip="statusMessage">
          <i class="icon red warning sign" @click="openReportStatusPage" />
        </span>
      </div>
    </div>

    <div class="ui container stackable grid middle aligned form">
      <div class="thirteen wide column">
        <div
          class="ui menu secondary fitted compact stackable borderless logo fluid"
        >
          <div class="img item">
            <vue-dropzone
              v-if="editable && !tenantLogo"
              id="tenantLogoDropzone"
              ref="tenantDropzone"
              :include-styling="false"
              :options="tenantDropzoneOptions"
              @vdropzone-success="tenantUploadComplete"
            />

            <div class="tenant-logo">
              <img v-if="tenantLogo" class="ui" :src="tenantLogo" />
              <div v-if="editable" class="ui dimmer inverted">
                <i class="ui icon trash small grey" @click="removeTenantLogo" />
              </div>
            </div>
          </div>

          <div
            v-if="tenantLogo && (editable || logoOrSponsorLogo)"
            class="icon plus"
          >
            <i class="ui plus icon fitted" />
          </div>

          <div class="img item">
            <vue-dropzone
              v-if="editable && !logoOrSponsorLogo"
              id="logoDropzone"
              ref="dropzone"
              :include-styling="false"
              :options="dropzoneOptions"
              @vdropzone-success="uploadComplete"
            />

            <div v-if="logoOrSponsorLogo" class="brand-logo">
              <img
                class="ui"
                :src="`${logoOrSponsorLogo}?token=${report.access_token}`"
              />
              <div v-if="editable" class="ui dimmer inverted">
                <i class="ui icon trash small grey" @click="removeLogo" />
              </div>
            </div>
          </div>

          <div class="item name">
            <span v-if="!editable">
              {{ report.name }}
            </span>
            <div v-if="editable" class="ui input">
              <input v-model.lazy="report.name" placeholder="Report Title" />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="deliverables.length > 0 && report.id"
        class="three wide column item last-updated-text"
      >
        <a
          v-if="!accessToken"
          :href="sharePath"
          data-remote="true"
          title="Share report"
          class="ui primary button"
        >
          <i class="share icon" />
          Share Report
        </a>

        <template v-if="lastUpdated">
          <small class="last-updated-small-text">
            <span v-if="lastUpdated"> Updated {{ lastUpdated }} </span>
            <a
              v-if="editable"
              title="Refresh report data now"
              class="ui link icon"
              @click="refreshReport"
            >
              <i :class="{ loading: refreshingStats }" class="icon sync" />
              <span v-if="!lastUpdated"> Update now </span>
            </a>
          </small>
        </template>
      </div>

      <div v-if="editable" class="sixteen wide column">
        <text-editor
          v-model="report.sections.description"
          :class="{ empty: !report.sections.description }"
          :initial-config="textEditorConfig"
          placeholder="Click here to add notes (optional)"
          class="clearing floating-menu"
        />
      </div>
      <div
        v-if="!editable && report.sections.description"
        id="reportDescription"
        class="sixteen wide column field"
      >
        <div class="clearing" v-html="autolinkedNotes" />
      </div>
    </div>
  </header>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState, mapGetters } from "vuex";
// import _ from "lodash";
import Toasted from "vue-toasted";
import textEditor from "components/textEditor.vue";
import VueDropzone from "vue2-dropzone";
import linkifyHtml from "linkify-html";

// const $ = window.jQuery;
const CKEDITOR = window.CKEDITOR;

if (!Object.keys(CKEDITOR.stylesSet.registered).includes("fomantic-ui")) {
  CKEDITOR.stylesSet.add("fomantic-ui", [
    { name: "Header 1", element: "h1", attributes: { class: "ui header" } },
    { name: "Header 2", element: "h2", attributes: { class: "ui header" } },
    { name: "Header 3", element: "h3", attributes: { class: "ui header" } },
    {
      name: "Celled Table",
      element: "table",
      attributes: { class: "ui celled table" },
    },
    {
      name: "Striped Table",
      element: "table",
      attributes: { class: "ui striped table" },
    },
    {
      name: "Basic Table",
      element: "table",
      attributes: { class: "ui basic table" },
    },
    { name: "Basic Button", element: "a", attributes: { class: "ui button" } },
    {
      name: "Primary Button",
      element: "a",
      attributes: { class: "ui primary button" },
    },
    {
      name: "Content Segment",
      element: "div",
      attributes: { class: "ui segment" },
    },
  ]);
}

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
});

export default {
  components: {
    VueDropzone,
    textEditor,
  },
  props: {
    lastUpdated: null,
    editable: false,
    resource: Object,
    initialReport: {
      type: Object,
      default() {
        return {};
      },
    },
    resourcePath: String,
    deliverableTypes: Array,
    typeIndex: Object,
    colors: Object,
    initialTenantLogo: String,
  },
  data() {
    const $this = this;
    return {
      defaultTenantLogo: $this.initialTenantLogo,
      refreshingStats: false,
      textEditorConfig: {
        stylesSet: "fomantic-ui",
        autoParagraph: false,
        extraPlugins: "image2,tableresize,emoji,uploadimage,embed",
        removePlugins: "showborders",
        uploadUrl: "/uploads.json",
        embed_provider:
          "//iframe.ly/api/oembed?url={url}&callback={callback}&api_key=6f391ac56a3121de535796",

        resize_maxWidth: "100",
        toolbar: [
          {
            name: "links",
            items: ["Link", "Unlink", "Image", "Embed"],
          },
          {
            name: "basicstyles",
            items: ["Bold", "Italic", "Underline", "Styles", "RemoveFormat"],
          },
          {
            name: "paragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "Blockquote",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
            ],
          },

          { name: "colors", items: ["TextColor", "BGColor"] },
          {
            name: "insert",
            items: ["Table", "HorizontalRule"],
          },
        ],
        filebrowserBrowseUrl: "/uploads",
        filebrowserImageBrowseLinkUrl: "/uploads",
        filebrowserImageBrowseUrl: "/uploads",
        height: 100,
      },
    };
  },
  computed: {
    ...mapState("report", {
      accessToken: "accessToken",
      report: "report",
      deliverables: "deliverables",
      autolinkedNotes() {
        return linkifyHtml(unescape(this.report.sections.description));
      },
    }),
    ...mapGetters("report", [
      "hasDeliverableErrors",
      "deliverableErrorsCount",
      "deliverableNotAttemptedCount",
    ]),
    showStatusMessage() {
      return (
        this.deliverableErrorsCount + this.deliverableNotAttemptedCount > 0
      );
    },
    statusMessage() {
      let message = "";
      if (this.deliverableErrorsCount > 0) {
        message = `${this.deliverableErrorsCount} deliverable${
          this.deliverableErrorsCount > 1 ? "s" : ""
        } not pulling stats.`;
      }

      if (this.deliverableNotAttemptedCount > 0) {
        message = `${message} ${this.deliverableNotAttemptedCount} deliverable${
          this.deliverableNotAttemptedCount > 1 ? "s" : ""
        } not fetched yet.`;
      }
      return message;
    },
    logoOrSponsorLogo() {
      if (this.report.sections.logo) {
        return this.report.sections.logo.content;
      } else if (this.report.sponsor_logo) {
        return this.report.sponsor_logo;
      } else {
        return false;
      }
    },
    tenantLogo() {
      if (this.report.sections.tenant_logo) {
        return `${this.report.sections.tenant_logo.content}?token=${this.report.access_token}`;
      } else if (this.defaultTenantLogo) {
        return `${this.defaultTenantLogo}?token=${this.report.access_token}`;
      } else {
        return false;
      }
    },
    reportPath() {
      return `/reports/${this.report.id}`;
    },
    refreshPath() {
      return `${this.reportPath}/refresh`;
    },
    sharePath() {
      return `/reports/report/${this.report.id}/share`;
    },
    // getUpdatesPath() {
    //   return `/reports/${this.report.id}/get_updates.js`;
    // },
    tenantDropzoneOptions() {
      return {
        maxFilesize: 10,
        thumbnailWidth: 100,
        previewTemplate: `
              <div class="dz-preview dz-file-preview">
                <div class="dz-progress">
                  <span class="dz-upload" data-dz-uploadprogress></span>
                </div>
              </div>
              <div class="dz-error-message"><span data-dz-errormessage></span></div>
        `,
        maxFiles: 1,
        // url: `/tenants/${this.report.tenant_id}/settings`,
        url: this.reportPath,
        paramName: "tenant_logo",
        method: "patch",
        acceptedFiles: "image/*",
        headers: {
          "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content"),
        },
        dictDefaultMessage: "<i class='ui icon cloud upload'></i>",
      };
    },
    dropzoneOptions() {
      return {
        maxFilesize: 10,
        thumbnailWidth: 100,
        previewTemplate: `
              <div class="dz-preview dz-file-preview">
                <div class="dz-progress">
                  <span class="dz-upload" data-dz-uploadprogress></span>
                </div>
              </div>
              <div class="dz-error-message"><span data-dz-errormessage></span></div>
        `,
        maxFiles: 1,
        url: this.reportPath,
        paramName: "logo",
        method: "patch",
        acceptedFiles: "image/*",
        headers: {
          "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content"),
        },
        dictDefaultMessage: "<i class='ui icon cloud upload'></i>",
      };
    },
  },
  watch: {},
  methods: {
    openReportStatusPage() {
      window.open(
        `/reports/${this.report.id}/status?q[with_reporting_errors]=1`,
        "_blank"
      );
    },
    reportFilterChanged(value) {
      this.$emit("reportFilterChanged", value);
    },
    tenantUploadComplete(file, report) {
      this.$refs.tenantDropzone.removeFile(file);
      // this.$set(this, "tenantLogo", tenant.logo.url);
      this.$set(
        this.report.sections,
        "tenant_logo",
        report.sections.tenant_logo
      );
      Vue.toasted.success("Saved");
    },
    uploadComplete(file, report) {
      this.$refs.dropzone.removeFile(file);
      this.$set(this.report.sections, "logo", report.sections.logo);
      Vue.toasted.success("Saved");
    },
    removeLogo() {
      this.report.sections.logo = null;
      this.report.sponsor_logo = null;
    },
    removeTenantLogo() {
      this.report.sections.tenant_logo = null;
      this.defaultTenantLogo = null;
    },
    refreshReport() {
      this.refreshingStats = true;

      window.$.read(`${this.refreshPath}.json`).done((data) => {
        this.refreshingStats = false;
        Vue.toasted.success(data.message);
      });
    },
  },
};
</script>

<style lang="scss">
div[contenteditable] {
  outline: 0;
}
</style>

<style lang="scss" scoped>
@import "dropzone/dist/dropzone.css";

.head {
  background: #ffffff;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .head {
    padding: 12px 0;
  }
}
@media (min-width: 1200px) {
  .head {
    padding: 24px 0;
  }
}

label.empty {
  text-indent: -99999999px;
}

/deep/ .ckeditor {
  // &.empty {
  //   &:before {
  //     content: "Click here to add notes (optional)";
  //     position: absolute;
  //     opacity: 0.5;
  //   }
  // }
}

#reportDescription {
  margin-bottom: 2rem;
}

#logoDropzone,
#tenantLogoDropzone {
  width: 150px;
  height: 80px;
  margin-right: 0.5em;
  border: 2px dashed rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  transition: border 200ms ease-out;

  &:before {
    content: "\f382";
    font-family: Icons;
    color: rgba(0, 0, 0, 0.22);
    display: flex;
    height: 60%;
    justify-content: center;
    align-items: center;
    font-size: 26px !important;
  }
  &:after {
    content: "Sponsor logo";
    font-size: 14px;
    color: rgba(0, 0, 0, 0.22);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.dz-started {
    &:before,
    &:after {
      // content: "";
    }
  }
  &:hover {
    border-color: #ccc;
    &:before,
    &:after {
      color: #ccc;
    }
  }
}

#tenantLogoDropzone:after {
  content: "Your logo";
}

.ui.menu {
  .item.img img {
    margin: 0px auto;
  }

  div.icon.plus {
    font-size: 1.33rem;
    color: #d8d8d8;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  .item.name {
    flex: auto !important;
  }

  @media (max-width: 768px) {
    .item.name {
      justify-content: center !important;
    }
    div.icon.plus {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .brand-logo {
    position: relative;
    margin: 0px auto;
    &:hover {
      .dimmer {
        display: flex;
        opacity: 1;
      }
    }
  }
  .tenant-logo {
    position: relative;
    margin: 0px auto;
    &:hover {
      .dimmer {
        display: flex;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .last-updated-text {
    padding: 0.5rem 1rem !important;
  }

  .last-updated-small-text {
    display: flex !important;
    justify-content: center !important;
  }
}
</style>
