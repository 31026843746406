<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
    class="ui grid header"
    :class="{
      'margin-bottom-large': isMobile,
      stackable: isMobile,
    }"
  >
    <div class="column two wide centered">
      <img v-if="showTenantLogo" :src="user.tenant_logo" />
    </div>
    <div class="fourteen wide column">
      <h3
        class="ui header"
        :class="{ pointer: userGroupsSize > 1 }"
        @click="toggleSection"
      >
        {{ username }}
        <i
          v-if="userGroupsSize > 1"
          class="icon caret grey small margin-bottom-mini"
          :class="{ right: collapsed, down: !collapsed }"
        />
      </h3>
      <div class="ui divider" />

      <div v-if="chartData" class="ui three column stackable grid">
        <div
          v-for="type in ['impressions', 'views', 'engagements']"
          v-if="chartData[type]"
          :key="`${user.id}-${type}`"
          class="column"
        >
          <div class="ui grid row">
            <div class="four wide column centered">
              <pie-chart
                height="50px"
                :legend="false"
                :donut="true"
                :colors="chartColors(type)"
                :data="chartData[type]"
              />
            </div>
            <div class="twelve wide column">
              <div class="ui statistic tiny left aligned">
                <div class="value">
                  {{ statsTotals(type) | abbreviateNumber(10000000) }}
                </div>
                <div class="label">
                  {{ type }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span
        v-if="youTubeDeliverables.length > 0"
        class="ui center aligned text tiny margin-top-medium"
      >
        <strong>YouTube</strong> metrics are not aggregated alongside other
        content channels (<a
          href="http://help.influencekit.com/en/articles/2138024-what-metrics-are-available-on-reports-and-what-do-they-mean"
          target="_blank"
          >learn why</a
        >)
      </span>
    </div>

    <a
      v-if="editable && report.reportable_type == 'Assignment'"
      class="ui top right attached label hovering link"
      :href="`/influencer_hub/assignments/${report.reportable_id}/for_influencer_user/${user.id}`"
      target="_blank"
    >
      <span>
        Assignment
        <i
          class="icon link external"
          style="-webkit-transition: none; transition: none !important"
        ></i>
      </span>
    </a>
    <a
      v-if="editable && report.reportable_type == 'PartnerCampaign'"
      class="ui top right attached label hovering link"
      :href="`/influencer_hub/${report.reportable_id}/influencer/${user.id}`"
      target="_blank"
    >
      <span>
        Assignments
        <i
          class="icon link external"
          style="-webkit-transition: none; transition: none !important"
        ></i>
      </span>
    </a>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Avatar from "components/avatar.vue";
import Chartkick from "chartkick";
import VueChartkick from "vue-chartkick";
import { map, sum } from "lodash";
import { mapState } from "vuex";
import store from "store";
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(VueObserveVisibility);
Vue.use(VueChartkick, { Chartkick });

const $ = window.jQuery;

export default {
  store,
  components: {
    // Avatar
  },
  props: {
    username: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    deliverables: {
      type: Array,
      default() {
        return [];
      },
    },
    stories: {
      type: Array,
      default() {
        return [];
      },
    },
    userGroupsSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      chartData: null,
      collapsed: false,
    };
  },
  computed: {
    ...mapState("deliverable", {
      colors: "colors",
    }),
    ...mapState("report", ["report", "editable"]),
    allDeliverables() {
      return this.deliverables.concat(this.stories);
    },
    allDeliverablesExcludingYouTube() {
      return this.allDeliverables.filter((d) => d.inferred_type != "youtube");
    },
    youTubeDeliverables() {
      return this.allDeliverables.filter((d) => d.inferred_type == "youtube");
    },
    deliverableIds() {
      return map(this.allDeliverables, "id");
    },
    deliverableIdsExcludingYouTube() {
      return map(this.allDeliverablesExcludingYouTube, "id");
    },
    isSameTenant() {
      return this.user.tenant_id === this.report.tenant_id;
    },
    showTenantLogo() {
      return !this.isSameTenant && this.user.tenant_logo;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  mounted() {},
  methods: {
    toggleSection() {
      if (this.userGroupsSize > 1) {
        this.collapsed = !this.collapsed;
        this.$emit("toggle", this.user.id);
      }
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.loadChartData();
      }
    },
    loadChartData() {
      if (!this.chartData) {
        $.ajax({
          url: `/statistics/pie_chart_data?deliverable_ids=${this.deliverableIds}&report_id=${this.report.id}&access_token=${this.report.access_token}`,
        }).then((data) => {
          this.chartData = data;
        });
      }
    },
    statsTotals(t) {
      const total = sum(Object.values(this.chartData[t]));
      return total;
    },
    chartColors(type) {
      const r = [];
      Object.keys(this.chartData[type]).forEach((k) => {
        r.push(this.colors[k]);
      });
      return r;
    },
  },
};
</script>

<style lang="css" scoped></style>
